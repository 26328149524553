export default class Game {
  gameID:number

  contestantNick: string

  result: number

  time: Date

  constructor(gameID: number, contestantNick: string, result: number, timestamp: string) {
    const t = new Date(timestamp);
    this.gameID = gameID;
    this.contestantNick = contestantNick;
    this.result = result;
    this.time = t;
  }

  filterOnTime(timeFilter: Date):boolean {
    return timeFilter.valueOf() < this.time.valueOf();
  }
}
