import Game from '@/classes/Game';

export interface Stats {
  contestant: string,
  participation: number,
  ranks: Array<number>,
  wins: number,
  soloWins: number,
  totalScore: number,
}
export interface RollAvg{
  labels:Array<string>,
  datasets: Array<{
    label: string,
    borderColor: string,
    data:Array<number|null>,
  }>
}

export interface HeatMapDataStruct{
  categories:Array<string>,
  series: Array<{
    name: string,
    data: Array<number|null>,
  }>
}

export default class Games {
  private events: Array<Game> = []

  private eventsById: Map<number, Array<Game>> = new Map()

  latestGameNumber = 0;

  private eventsByWeek: Map<number, Array<Game>> = new Map();

  private nicks:Array<string> = [];

  // private static firstGameDay = new Date(2021, 5, 1);

  private getColorFromNick(nick: string):string {
    const colors = [
      '#D14B7D',
      '#5A3429',
      '#ACC29D',
      '#8FDAF6',
      '#4908DF',
      '#271A35',
      '#9CD961',
      '#093E6D',
      '#E6BB0B',
      '#D001C3',
    ];
    const i = this.nicks.indexOf(nick);
    if (i === -1) {
      return colors[colors.length - 1];
    }
    return colors[i];
  }

  async populate() {
    const res = await fetch('https://api.roman.nu/wordle');
    const rj = await res.json();
    if (Array.isArray(rj)) {
      rj.forEach((item) => {
        this.addGame(new Game(item.game_nr, item.contestant, item.result, item.game_date));
      });
    }
  }

  addGame(game: Game): void {
    this.events.push(game);
    this.latestGameNumber = (this.latestGameNumber < game.gameID)
      ? game.gameID : this.latestGameNumber;
    const e = this.eventsById.get(game.gameID);
    if (e) {
      e.push(game);
      this.eventsById.set(game.gameID, e);
    } else {
      this.eventsById.set(game.gameID, [game]);
    }
    const ebw = this.eventsByWeek.get(Games.getWeekFromDate(game.time));
    if (ebw) {
      ebw.push(game);
    } else {
      this.eventsByWeek.set(Games.getWeekFromDate(game.time), [game]);
    }
    if (!this.nicks.find((item) => item.localeCompare(game.contestantNick) === 0)) {
      this.nicks.push(game.contestantNick);
    }
  }

  getGamesById(id: number): Array<Game> {
    return this.events.filter((event) => event.gameID === id);
  }

  gamestAfterId(id: number): Array<Game> {
    return this.events.filter((event) => event.gameID >= id);
  }

  gamesBeforeDate(t: Date): Array<Game> {
    return this.events.filter((event) => !event.filterOnTime(t));
  }

  filterGameStats(games: number):Array<Stats> {
    const d = new Date();
    d.setDate(d.getDate() - games);
    this.eventsById.clear();
    this.gamestAfterId(this.latestGameNumber - games).forEach((g) => {
      const e = this.eventsById.get(g.gameID);
      if (e) {
        e.push(g);
        this.eventsById.set(g.gameID, e);
      } else {
        this.eventsById.set(g.gameID, [g]);
      }
    });
    return this.calculateStats();
  }

  calculateStats(): Array<Stats> {
    const stats:Map<string, Stats> = new Map();
    this.eventsById.forEach((game) => {
      const result:Map<number, Array<string>> = new Map();
      game.forEach((g) => {
        const p = result.get(g.result);
        if (p) {
          p.push(g.contestantNick);
          result.set(g.result, p);
        } else {
          result.set(g.result, [g.contestantNick]);
        }
      });
      // What is the winning score?
      const keys:Array<number> = [];
      result.forEach((r, k) => {
        keys.push(k);
      });
      keys.sort((a, b) => a - b);
      const winnerRank = keys[0];
      const wr = result.get(winnerRank);
      const soloWinner = (wr && wr.length === 1);
      game.forEach((g) => {
        const wc = (g.result === winnerRank) ? 1 : 0;
        const sw = (g.result === winnerRank && soloWinner) ? 1 : 0;
        const tc = stats.get(g.contestantNick);
        if (tc) {
          tc.soloWins += sw;
          tc.participation += 1;
          tc.wins += wc;
          tc.ranks.push(g.result);
          tc.totalScore += g.result;
          stats.set(g.contestantNick, tc);
        } else {
          stats.set(g.contestantNick, {
            soloWins: sw,
            participation: 1,
            ranks: [g.result],
            totalScore: g.result,
            wins: wc,
            contestant: g.contestantNick,
          });
        }
      });
    });
    return Array.from(stats.values()).map((item) => item);
  }

  private static getWeekFromDate(d: Date):number {
    const date = new Date(d);
    // Find Thursday of this week starting on Monday
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const thursday = date.getTime();

    // Find January 1st
    date.setMonth(0); // January
    date.setDate(1);
    const jan1st = date.getTime();

    // Round the amount of days to compensate for daylight saving time
    const days = Math.round((thursday - jan1st) / 86400000); // 1 day = 86400000 ms
    return Math.floor(days / 7) + 1;
  }

  private static avgResPerWeek(m: Map<string, Array<Game>>):Map<string, number> {
    const nm: Map<string, number> = new Map();
    m.forEach((v, k) => {
      const r = v.map((item) => item.result).reduce((c, n) => c + n);
      nm.set(k, r / v.length);
    });
    return nm;
  }

  lineChartData():HeatMapDataStruct {
    const d = this.sumAvg();
    return {
      categories: d.labels,
      series: d.datasets.map((v) => ({
        name: v.label,
        data: v.data,
      })),
    };
  }

  sumAvg(numberOfGames?:number) {
    const {
      labels, gameMap, startWeek, endWeek,
    } = this.groupGamesByWeek((numberOfGames) || 67);
    const ds:Map<string, Array<number|null>> = new Map();
    for (let x = startWeek; x < endWeek; x += 1) {
      gameMap.forEach((v, k) => {
        const z = v.get(x);
        let num = null;
        if (z) {
          num = z.reduce((a, b) => a + b) / z.length;
        }
        const l = ds.get(k);
        if (l) {
          l.push(num);
        } else {
          ds.set(k, [num]);
        }
      });
    }
    return {
      labels,
      datasets: Array.from(ds).map((item) => ({
        label: item[0].toString(),
        data: item[1],
        borderColor: this.getColorFromNick(item[0]),
      })),
    };
  }

  participationHeatMap(numberOfGames?:number):HeatMapDataStruct {
    const {
      labels, gameMap, startWeek, endWeek,
    } = this.groupGamesByWeek((numberOfGames) || 67);
    const ds:Map<string, Array<number|null>> = new Map();
    for (let x = startWeek; x < endWeek; x += 1) {
      gameMap.forEach((v, k) => {
        const z = v.get(x);
        const num = (z) ? z.length : 0;
        const l = ds.get(k);
        if (l) {
          l.push(num);
        } else {
          ds.set(k, [num]);
        }
      });
    }
    return {
      categories: labels,
      series: Array.from(ds).map((item) => ({
        name: item[0].toString(),
        data: item[1],
      })),
    };
  }

  private groupGamesByWeek = (nrOfGames:number) => {
    const d = new Date();
    const endWeek = Games.getWeekFromDate(d);
    d.setDate(d.getDate() - nrOfGames);
    const startWeek = Games.getWeekFromDate(d);
    const sag:Map<string, Map<number, Array<number>>> = new Map();
    const lbls:Array<string> = [];
    for (let x = startWeek; x < endWeek; x += 1) {
      const e = this.eventsByWeek.get(x);
      if (e) {
        lbls.push(`2024-${x.toString()}`);
        e.forEach((item) => {
          const comp = sag.get(item.contestantNick);
          if (comp) {
            const gms = comp.get(x);
            if (gms) {
              gms.push(item.result);
            } else {
              comp.set(x, [item.result]);
            }
          } else {
            const nm = new Map();
            nm.set(x, [item.result]);
            sag.set(item.contestantNick, nm);
          }
        });
      }
    }
    return {
      labels: lbls,
      gameMap: sag,
      startWeek,
      endWeek,
    };
  };

  private getEventsOnNick(numberOf: number):Map<string, Array<Game>> {
    const ra:Map<string, Array<Game>> = new Map();
    this.events.filter((g) => g.gameID > this.latestGameNumber - numberOf)
      .forEach((g) => {
        const t = ra.get(g.contestantNick);
        if (t) {
          t.push(g);
          ra.set(g.contestantNick, t);
        } else {
          ra.set(g.contestantNick, [g]);
        }
      });
    return ra;
  }

  heatMapData(numberOfGames?:number):HeatMapDataStruct {
    const nrOfGames = (numberOfGames) || 30;
    const ra = this.getEventsOnNick(nrOfGames);
    ra.forEach((v, k) => {
      v.sort((a, b) => a.gameID - b.gameID);
      ra.set(k, v);
    });
    const z:Map<string, Array<number>> = new Map();
    const lbls:Array<string> = [];
    for (let x = this.latestGameNumber - (nrOfGames - 1); x <= this.latestGameNumber; x += 1) {
      ra.forEach((g, k) => {
        const o = g.find((v) => v.gameID === x);
        const oo = z.get(k);
        const res = (o) ? o.result : 0;
        if (oo) {
          oo.push(res);
          z.set(k, oo);
        } else {
          z.set(k, [res]);
        }
      });
      const l = this.getGamesById(x)[0] ? this.getGamesById(x)[0].time.toLocaleDateString() : '';
      lbls.push(l);
    }
    return {
      categories: lbls,
      series: Array.from(z).map((item) => ({
        name: item[0].toString(),
        data: item[1],
      })),
    };
  }
}

/*
type: 'line',
data: {
  datasets: [{
    data: [{x: 10, y: 20}, {x: 15, y: null}, {x: 20, y: 10}]
  }]
}
 */
