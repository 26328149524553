
import {
  PropType, defineComponent,
} from 'vue';
import {
  CTable, CTableHead, CTableRow, CTableBody, CTableHeaderCell, CTableDataCell,
} from '@coreui/vue';
import { Stats } from '@/classes/Games';

export default defineComponent({
  name: 'DataTable',
  components: {
    CTable, CTableHead, CTableRow, CTableBody, CTableHeaderCell, CTableDataCell,
  },
  props: {
    stats: { type: Array as PropType<Array<Stats>>, required: true },
  },
  setup(props) {
    return { props };
  },
});

