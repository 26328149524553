
import {
  defineComponent, PropType,
} from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { HeatMapDataStruct } from '@/classes/Games';

export default defineComponent({
  name: 'MatrixChartSingle',
  components: {
    VueApexCharts,
  },
  props: {
    data: { type: Object as PropType<HeatMapDataStruct>, required: true },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const chartOptions = {
      chart: {
        height: 550,
        type: 'heatmap',
      },
      colors: ['#0c7504'],
      plotOptions: {
        heatmap: {
          shadeIntensity: 1,
          radius: 0,
          useFillColorAsStroke: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        type: 'category',
        categories: props.data.categories,
      },
      stroke: {
        width: 1,
      },
      title: {
        text: 'Deltagande per vecka',
      },
    };
    return {
      chartOptions,
      series: props.data.series,
    };
  },
});
