
import {
  defineComponent, PropType,
} from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { HeatMapDataStruct } from '@/classes/Games';
import Words from '@/classes/Words';
import { ApexOptions } from 'apexcharts';

export default defineComponent({
  name: 'MatrixChart',
  components: {
    VueApexCharts,
  },
  props: {
    data: { type: Object as PropType<HeatMapDataStruct>, required: true },
    words: { type: Object as PropType<Words>, required: true },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const chartOptions:ApexOptions = {
      chart: {
        height: 550,
        type: 'heatmap',
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          radius: 0,
          useFillColorAsStroke: true,
          colorScale: {
            ranges: [{
              from: 0,
              to: 1,
              name: '❌',
              color: '#6c6d6a',
            },
            {
              from: 1,
              to: 2,
              name: '🤯',
              color: '#606c38',
            },
            {
              from: 2,
              to: 3,
              name: '🔥',
              color: '#2a9d8f',
            },
            {
              from: 3,
              to: 4,
              name: '😎',
              color: '#e9c46a',
            },
            {
              from: 4,
              to: 5,
              name: '🥱',
              color: '#ff6a00',
            },
            {
              from: 5,
              to: 6,
              name: '💀',
              color: '#3a86ff',
            },
            {
              from: 6,
              to: 7,
              name: '🤮',
              color: '#dc5863',
            },
            {
              from: 7,
              to: 8,
              name: '💩',
              color: '#ec091a',
            },
            ],
          },
        },
      },
      tooltip: {
        y: {
          // eslint-disable-next-line
          formatter: (val: number, opts: any) => props.words.getWordByIndex((props.data.categories.length - 1) - opts.dataPointIndex),
        },
        x: {
          // eslint-disable-next-line
          formatter: (val: number, opts?: any) => {
            return val.toString();
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        type: 'category',
        categories: props.data.categories,
        labels: {
          rotateAlways: true,
        },
      },
      stroke: {
        width: 1,
      },
      title: {
        text: 'Resultat per dag',
      },
    };
    return {
      chartOptions,
      series: props.data.series,
    };
  },
});
