
import { defineComponent, onMounted, ref } from 'vue';

import '@coreui/coreui/dist/css/coreui.min.css';
import Games, { HeatMapDataStruct, Stats } from '@/classes/Games';
import DataTable from '@/components/DataTable.vue';
import MatrixSingleColor from '@/components/MatrixSingleColor.vue';
import { CSpinner } from '@coreui/vue';
import BarChart from '@/components/BarChart.vue';
import FilterButton from './components/FilterButton.vue';
import MatrixChart from './components/Matrix.vue';
import PieChart from './components/Doughnut.vue';
import LineChartApx from './components/LineChart.vue';
import Words, { BarChartSeries } from './classes/Words';

export default defineComponent({
  name: 'App',
  components: {
    DataTable,
    FilterButton,
    MatrixChart,
    LineChartApx,
    MatrixSingleColor,
    CSpinner,
    BarChart,
    PieChart,
  },
  setup() {
    const games = ref<Games>(new Games());
    const words = ref<Words>(new Words());
    const stats = ref<Array<Stats>>();
    const chartData = ref<HeatMapDataStruct>();
    const heatMapData = ref<HeatMapDataStruct>();
    const heatMapDataParticipation = ref<HeatMapDataStruct>();
    const charFrequencyFirst = ref<BarChartSeries>();
    const charFrequency = ref<BarChartSeries>();
    const consonantFrequency = ref<BarChartSeries>();
    const doubleFrequency = ref<BarChartSeries>();
    const showing = ref<string>('table');
    const loading = ref<boolean>(true);
    const sampleSize = 200;
    onMounted(async () => {
      await games.value.populate();
      stats.value = games.value.calculateStats().sort((a, b) => b.wins - a.wins);
      chartData.value = games.value.lineChartData();
      heatMapData.value = games.value.heatMapData();
      heatMapDataParticipation.value = games.value.participationHeatMap();
      await words.value.populate();
      // words.value.splitWordsByChar();
      charFrequencyFirst.value = words.value.startLetterFrequency(sampleSize);
      charFrequency.value = words.value.letterFrequency(sampleSize);
      consonantFrequency.value = words.value.consonantFrequency(sampleSize);
      doubleFrequency.value = words.value.doubleFrequency(sampleSize);
      loading.value = false;
    });
    const filterStats = (numberOfGames:number) => {
      stats.value = games.value.filterGameStats(numberOfGames).sort((a, b) => b.wins - a.wins);
    };
    const showType = (t:string) => {
      showing.value = t;
    };
    const showThis = (t:string) => showing.value === t;
    return {
      games,
      stats,
      filterStats,
      showThis,
      showType,
      showing,
      chartData,
      heatMapData,
      words,
      heatMapDataParticipation,
      charFrequencyFirst,
      consonantFrequency,
      charFrequency,
      doubleFrequency,
      loading,
      sampleSize,
    };
  },
});
