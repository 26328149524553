import { createApp } from 'vue';
import { CIcon } from '@coreui/icons-vue';
import {
  cilInfinity, cilGrid, cilChart, cilGradient, cilTextSquare,
} from '@coreui/icons';
import App from './App.vue';

const icons = {
  cilInfinity,
  cilGrid,
  cilChart,
  cilGradient,
  cilTextSquare,
};

const app = createApp(App);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.mount('#app');
