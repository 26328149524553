
import {
  CButton,
} from '@coreui/vue';
import {
  defineComponent, ref,
} from 'vue';

export default defineComponent({
  name: 'FilterButton',
  components: {
    CButton,
  },
  props: {
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filter: (numberOfGames: number) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    displayType: (t:string) => true,
  },
  setup(props, { emit }) {
    const displayFilterButtons = ref<boolean>(true);
    const filterStats = (numberOfGames:number) => {
      emit('filter', numberOfGames);
    };
    const weekFilter = (new Date()).getDay() - 1;
    const display = (t:string) => {
      displayFilterButtons.value = (t === 'table');
      emit('displayType', t);
    };
    return {
      filterStats, display, displayFilterButtons, weekFilter,
    };
  },
});

