
import {
  defineComponent, PropType,
} from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { HeatMapDataStruct } from '@/classes/Games';
import { ApexOptions } from 'apexcharts';

export default defineComponent({
  name: 'LineChartApx',
  components: {
    VueApexCharts,
  },
  props: {
    data: { type: Object as PropType<HeatMapDataStruct>, required: true },
  },
  setup(props) {
    const chartOptions:ApexOptions = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      tooltip: {
        y: {
          formatter: (val: number) => val.toFixed(2).toString(),
        },
        x: {
          // eslint-disable-next-line
          formatter: (val: number, opts: any) => {
            const p:string = opts.w.globals.categoryLabels[opts.dataPointIndex].split('-');
            return `År:${p[0]}, Vecka:${p[1]}`;
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 4,
        curve: 'smooth',
      },
      title: {
        text: 'Genomsnittsscore per vecka',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: {
        labels: {
          formatter(val: number) {
            return (val).toFixed(0);
          },
        },
        title: {
          text: 'Resultat',
        },
      },
      xaxis: {
        categories: props.data.categories,
      },
    };
    return {
      chartOptions,
      series: props.data.series,
    };
  },
});
