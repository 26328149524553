export interface Word {
  gameNr: number,
  word: string,
  charArray?:Array<string>,
}

export interface BarChartSeries {
  categories:Array<string>,
  series: Array<{
    data: Array<number|null>,
  }>
}

interface wordAPI {
  // eslint-disable-next-line camelcase
  game_nr: number,
  word: string,
}
export default class Words {
  words:Map<number, string>=new Map();

  wordsArr:Array<Word> = [];

  async populate() {
    const res = await fetch('https://api.roman.nu/words');
    const rj:Array<wordAPI> = await res.json();
    if (Array.isArray(rj)) {
      this.wordsArr = rj.sort((a, b) => b.game_nr - a.game_nr)
        .map((item) => ({
          gameNr: item.game_nr,
          word: item.word,
        }));
      rj.forEach((item) => {
        this.words.set(item.game_nr, item.word);
      });
    }
  }

  // Return x number of words sorted by game number
  getLatestWords(numberOfWords: number):Array<Word> {
    return this.wordsArr.slice(0, numberOfWords);
  }

  private vowels:Array<string> = ['A', 'O', 'U', 'E', 'I', 'Y'];

  consonantFrequency(sampleSize: number):BarChartSeries {
    const words = this.getLatestWords(sampleSize);
    const fwm:Map<string, number> = new Map();
    words.forEach((w) => {
      const chars = w.word.split('')
        .filter((c) => this.vowels.find((e) => e === c));
      const key = `${(w.word.length - chars.length)} konsonanter`;
      const i = fwm.get(key);
      if (i) {
        fwm.set(key, i + 1);
      } else {
        fwm.set(key, 1);
      }
    });
    return Words.barChartData(fwm);
  }

  static barChartData(fwm:Map<number|string, number>):BarChartSeries {
    const fwmSorted = new Map([...fwm.entries()].sort((a, b) => b[1] - a[1]));
    return {
      categories: Array.from(fwmSorted.keys()).map((k) => k.toString()),
      series: [{
        data: Array.from(fwmSorted.values()).map((v) => v),
      }],
    };
  }

  startLetterFrequency(sampleSize: number):BarChartSeries {
    const words = this.getLatestWords(sampleSize);
    const fwm:Map<string, number> = new Map();
    words.forEach((w) => {
      const chars = w.word.split('');
      const i = fwm.get(chars[0]);
      if (i) {
        fwm.set(chars[0], i + 1);
      } else {
        fwm.set(chars[0], 1);
      }
    });
    return Words.barChartData(fwm);
  }

  doubleFrequency(sampleSize: number):BarChartSeries {
    const words = this.getLatestWords(sampleSize);
    const fwm:Map<string, number> = new Map();
    let has = 0;
    let hasNot = 0;
    words.forEach((w) => {
      const chars = w.word.split('');
      // chars.sort((a, b) => a.localeCompare(b));
      if ((new Set(chars)).size !== chars.length) {
        has += 1;
      } else {
        hasNot += 1;
      }
    });
    fwm.set('Har dubbelförekomst', has);
    fwm.set('Har ej dubbelförekomst', hasNot);
    return Words.barChartData(fwm);
  }

  letterFrequency(sampleSize: number):BarChartSeries {
    const words = this.getLatestWords(sampleSize);
    const fwm:Map<string, number> = new Map();
    words.forEach((w) => {
      const chars = w.word.split('');
      chars.forEach((c) => {
        const i = fwm.get(c);
        if (i) {
          fwm.set(c, i + 1);
        } else {
          fwm.set(c, 1);
        }
      });
    });
    return Words.barChartData(fwm);
  }

  getWordByIndex(x: number): string {
    return this.wordsArr[x].word;
  }
}
