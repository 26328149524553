
import {
  defineComponent, PropType,
} from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { ApexOptions } from 'apexcharts';
import { BarChartSeries } from '@/classes/Words';

export default defineComponent({
  name: 'PieChart',
  components: {
    VueApexCharts,
  },
  props: {
    data: { type: Object as PropType<BarChartSeries>, required: true },
    headline: { type: String, required: true },
    sampleSize: { type: Number, required: true },
  },
  setup(props) {
    const chartOptions:ApexOptions = {
      chart: {
        type: 'pie',
        height: 350,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
      title: {
        text: `${props.headline} (n=${props.sampleSize})`,
        align: 'left',
      },
      labels: props.data.categories,
    };
    return {
      chartOptions,
      series: props.data.series[0].data,
    };
  },
});
